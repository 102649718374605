<template>
  <v-card class="ma-5">
    <DateInputs
      ref="dataInputRelatorio"
      v-model="relatorios"
      @searching="loading = $event"
      @filter="filter"
    />

    <base-loading v-if="loading" />
    <v-card v-else class="ma-2">
      <div>
        <gmap-map
          :center="center"
          :zoom="14"
          style="width: 100%; height: 500px"
        >
          <gmap-marker
            v-for="(m, index) in markers"
            :key="index"
            :position="m.position"
            :clickable="true"
            @click="center = m.position"
            @mouseover="showByIndex = index"
            @mouseout="showByIndex = null"
          >
            <gmap-info-window :opened="showByIndex === index">
              <p style="text-align: center; font-weight: bold">
                {{ getReportTitle(infomarkers[index].Tipo) }}
              </p>
              <p>
                Realizado no dia:
                {{
                  moment(infomarkers[index].DataAtendimento).format(
                    'DD/MM/YYYY',
                  )
                }}
              </p>
              <p>N.º da visita: {{ infomarkers[index].NumeroVisita }}</p>
              <p>Fazenda: {{ infomarkers[index].Fazenda }}</p>
              <p>Produtor: {{ infomarkers[index].Produtor }}</p>
              <p>Agroindustria: {{ infomarkers[index].Agroindustria }}</p>
            </gmap-info-window>
          </gmap-marker>
        </gmap-map>
      </div>
      <div v-show="visible">
        <crud-list
          v-model="reports"
          :headers="headers"
          :actions="actions"
          :slots="['item.DataAtendimento', 'item.Tipo']"
          :search-init="search"
          :search.sync="search"
        >
          <template v-slot:[`item.Tipo`]="{ item }">
            {{ getReportTitle(item.Tipo) }}
          </template>
          <template v-slot:[`item.DataAtendimento`]="{ item }">
            {{ moment(item.DataAtendimento).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:print>
            <v-btn
              v-if="reports.length != markers.length"
              style="color: white; font-size: 12px; font-weight: 500"
              small
              elevation="2"
              class="ml-3"
              color="blue darken-4"
              @click="printAll"
            >
              Selecionar todos
            </v-btn>
            <v-btn
              v-else
              style="color: white; font-size: 12px; font-weight: 500"
              small
              elevation="2"
              class="ml-3"
              color="blue darken-4"
              @click="removeAll"
            >
              Retirar todos
            </v-btn>
          </template>
        </crud-list>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import ReportTypeService from '../../../services/api/reportType'
import DateInputs from './DateInputs'

export default {
  components: {
    DateInputs,
  },

  data() {
    const viewReport = {
      title: 'Ver localização',
      color: 'primary',
      click: item => this.visualizar(item),
      icon: 'mdi-eye',
    }
    const selectReport = {
      title: 'checkbox',
      color: 'blue darken-3',
      click: item => this.selectItem(item),
    }
    let btnAction = []
    btnAction.push(viewReport, selectReport)

    return {
      visible: false,
      showByIndex: 0,
      coordenadas: [],
      center: { lat: 0, lng: 0 },
      markers: [],
      infomarkers: [],
      reports: [],
      loading: true,
      data: true,
      relatorios: [],
      relatoriosData: [],
      actions: btnAction,
      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'center',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Número da Visita',
          value: 'NumeroVisita',
          width: '80px',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Data da Visita',
          value: 'DataAtendimento',
          width: 'auto',
        },
        {
          text: 'Produtor',
          align: 'center',
          sortable: true,
          value: 'Produtor',
          width: 'auto',
        },
        {
          text: 'Fazenda',
          align: 'center',
          sortable: true,
          value: 'Fazenda',
          width: 'auto',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Agroindústria',
          value: 'Agroindustria',
          width: 'auto',
        },
        {
          text: 'Tipo',
          align: 'center',
          sortable: true,
          value: 'Tipo',
          width: '300px',
        },
        {
          text: 'Localização',
          align: 'center',
          sortable: true,
          value: 'locale',
          width: '30px',
        },
      ],
      reportsTypes: [],
    }
  },
  computed: {
    ...mapState(['relatorio_search']),
    search: {
      get() {
        return null
      },
      set(val) {
        this.$store.commit('SET_RELATORIO_SEARCH', val)
      },
    },
  },

  async mounted() {
    this.geolocate()
    await this.getReportsType()
  },

  methods: {
    async printAll() {
      this.filter(this.data)
    },

    async getReportsType() {
      ReportTypeService.list().then(response => {
        this.reportsTypes = response
      })
    },

    getReportTitle(id) {
      const res = this.reportsTypes.find(item => {
        if (item.id === id) {
          return item
        }
      })

      return res.name
    },

    async removeAll() {
      this.reports.forEach(item => {
        this.selectItem(item)
        item.checked = false
      })
    },

    visualizar(item) {
      this.center = {
        lat: parseFloat(item.Latitude),
        lng: parseFloat(item.Longitude),
      }

      const index = this.infomarkers.indexOf(item)

      this.showByIndex = index
    },

    selectItem(item) {
      const find = this.infomarkers.find(
        report => report.CodRelatorio === item.CodRelatorio,
      )

      if (!find) {
        this.coordenadas = {
          position: {
            lat: parseFloat(item.Latitude),
            lng: parseFloat(item.Longitude),
          },
        }
        this.markers.push(this.coordenadas)
        this.infomarkers.push(item)
      } else {
        const index = this.infomarkers.indexOf(item)
        this.markers.splice(index, 1)
        this.infomarkers.splice(index, 1)
      }
      this.markers.sort()
      this.infomarkers.sort()
      this.geolocate()
    },

    filter(data) {
      this.data = data
      this.markers = []
      this.infomarkers = []
      this.reports = []
      data.map(item => {
        if (item.Latitude != '' && item.Longitude != '') {
          this.center = {
            lat: parseFloat(item.Latitude),
            lng: parseFloat(item.Longitude),
          }
          this.coordenadas = {
            position: {
              lat: parseFloat(item.Latitude),
              lng: parseFloat(item.Longitude),
            },
          }
          item.locale = 'Sim'
          this.markers.push(this.coordenadas)
          this.infomarkers.push(item)
          this.reports.push(item)
        } else {
          item.locale = 'Não'
          this.reports.push(item)
        }
      })

      this.reports.forEach(item => {
        if (item.locale === 'Não') item.checked = false
        else item.checked = true
      })

      this.geolocate()
      this.visible = true
    },

    geolocate: function () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      })
    },
  },
}
</script>

<style>
.v-icon.v-icon::after {
  background-color: white;
}
</style>
