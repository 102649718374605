<template>
  <div>
    <v-row class="px-5" style="padding-top: 20px">
      <v-col class="pa-1" cols="12" sm="8" md="4">
        <v-menu
          v-model="menuDataIni"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="250px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="moment(dataInicio).format('DD/MM/YYYY')"
              label="Data Inicial"
              prepend-icon="mdi-calendar"
              color="teal"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dataInicio"
            style="margin: 0px"
            :max="dataFim"
            color="teal"
            locale="pt-br"
            @input="menuDataIni = false"
          />
        </v-menu>
      </v-col>
      <v-col class="pa-1" cols="12" sm="8" md="4">
        <v-menu
          v-model="menuDataFim"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="moment(dataFim).format('DD/MM/YYYY')"
              color="teal"
              label="Data Final"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dataFim"
            :min="dataInicio"
            :max="dataLimite"
            color="teal"
            locale="pt-br"
            @input="menuDataFim = false"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-5" cols="12" sm="7" md="4">
        <v-autocomplete
          v-model="form.consultor"
          prepend-icon="mdi-account-search"
          dense
          autocomplete="autocomplete_off_hack_xfr4!k"
          item-text="Nome"
          item-value="id"
          label="Selecione um Consultor"
          validate-on-blur
          clearable
          :loading="loading.consultor"
          :disabled="loading.consultor"
          :items="consultores"
          @change="isSelect()"
          @input="selectConsultor()"
        />
      </v-col>
      <v-col class="pa-5" cols="12" sm="7" md="4">
        <v-autocomplete
          v-model="form.agroindustria"
          prepend-icon="mdi-account-search"
          dense
          autocomplete="autocomplete_off_hack_xfr4!k"
          item-text="Nome"
          item-value="id"
          label="Selecione uma agroindústria"
          validate-on-blur
          clearable
          :loading="loading.agroindustria"
          :disabled="loading.agroindustria"
          :items="agroindustria"
          @change="isSelect()"
        />
      </v-col>
      <v-col class="pa-3" cols="12" md="2">
        <v-row justify="end" align="center">
          <v-btn
            :disabled="disabled"
            style="color: white; border-radius: 8px; margin-left: 40%"
            color="blue darken-4"
            @click="getRelatorios"
          >
            <span style="font-size: 16px; font-weight: 600">Buscar</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import agroindustryService from '../../../services/api/agroindustry'
import { _orderByName } from '../../../utils/formatter'
import { ENV_APP } from '../../../../env'
export default {
  name: 'DateInputsBP',

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ENV_APP,
      loading: {
        consultor: true,
        agroindustria: true,
      },
      form: {
        consultor: '',
        consultorBox: false,
        agroindustria: '',
      },

      rules: {
        required: value => !!value || 'Obrigatório.',
      },

      consultores: [],
      agroindustria: [],
      allAgroindustria: [],

      searching: true,
      disabled: true,
      dataLimite: this.moment().toISOString(true).substr(0, 10),
      menuDataIni: false,
      menuDataFim: false,
    }
  },
  computed: {
    ...mapState(['relatorio_dataInicio', 'relatorio_dataFim']),
    dataInicio: {
      get() {
        return this.$store.state.relatorio_dataInicio
          ? this.$store.state.relatorio_dataInicio
          : this.moment().subtract(1, 'months').toISOString(true).substr(0, 10)
      },
      set(val) {
        this.$store.commit('SET_RELATORIO_DATAINICIO', val)
      },
    },
    dataFim: {
      get() {
        return this.$store.state.relatorio_dataFim
          ? this.$store.state.relatorio_dataFim
          : this.moment().toISOString(true).substr(0, 10)
      },
      set(val) {
        this.$store.commit('SET_RELATORIO_DATAFIM', val)
      },
    },
  },

  created() {
    this.getConsultores()
    this.getAgroindustria()
  },

  methods: {
    getConsultores() {
      if (
        this.$user.get().role === 'admin' ||
        this.$user.get().role === 'gestor' ||
        this.$user.get().role === 'consultoria'
      ) {
        this.api.get.consultores().then(data => {
          this.consultores = _orderByName({
            data,
            key: 'Nome',
          })
          this.loading.consultor = false
          this.$emit('searching', false)
        })
      } else {
        this.loading.consultor = false
      }
    },

    getRelatorios() {
      this.searching = true
      this.$emit('searching', true)
      const consultor = this.consultores.find(
        consultor => consultor.id === this.form.consultor,
      )

      this.api.get
        .relatoriosMap(
          this.dataInicio,
          this.dataFim,
          ENV_APP.ProjectID,
          consultor?.user_id ?? null,
          this.form.agroindustria ?? null,
        )
        .then(data => {
          data = data.map(item => {
            item.Sincronized = item.Sincronized.substr(0, 10)
            return item
          })
          this.$emit('markers', false)
          this.$emit('input', data)
          this.$emit('filter', data)
          this.searching = false
          this.$emit('searching', false)
        })
      this.$root.$emit('searchingFilter', true)
    },

    async getAgroindustria() {
      const data = await agroindustryService.list()

      this.agroindustria = data
      this.allAgroindustria = data
      this.loading.agroindustria = false
    },

    isSelect() {
      this.disabled = false
    },

    selectConsultor() {
      this.form.agroindustria = ''

      if (!this.form.consultor) {
        this.agroindustria = this.allAgroindustria
        return
      }

      const consultorFazendas = this.consultores.find(
        consultor => consultor.id === this.form.consultor,
      )?.Fazendas

      let listAgros = []

      this.allAgroindustria.filter(agroindustria => {
        return agroindustria.agroindustria_fazenda?.filter(agros => {
          consultorFazendas?.filter(fazendas => {
            if (agros.fazendas?.CodFazenda === fazendas?.CodFazenda) {
              listAgros.push(agroindustria)
            }
          })
        })
      })

      this.agroindustria = listAgros
    },
  },
}
</script>
